h1{
    display:inline;
    margin:0;
    margin-bottom:0.4rem;
}

.header-text-container > p{
    margin:0;
}

.main-header{
    display:flex;
    flex-direction: column;
}

.header-container{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap:45px;
}

.header-text-container{
    display:flex;
    flex-direction: column;
    flex-grow: 1;
}

.header-pic{
    width:140px;
    border-radius: 100px;
    border:1px solid black;

    transition: filter 0.6s ease;
    -webkit-transition: filter 0.6s;
}

.header-pic:hover{
    filter: grayscale(100%);
}

.social-links{
    display:flex;
    flex-direction: row;
    gap:10px;
    justify-content: flex-start;
    margin:0.4rem 0;
    flex-wrap: wrap;
}

.social-links a::before{
    content: "↳ ";
    color: var(--font-color);
}

.header-mode-toggle{
    float:right;
    font-size:2rem;
    background-color: transparent;
    border:none;
}

.header-mode-toggle:hover{
    cursor:pointer;
}

@media only screen and (max-width: 850px) {
    .header-pic{
        display:none;
    }
}