body {
  --font-color: #0e0e0e;
  --background-color: #F4F3F2;
  --link-color: #FF7070;
  --link-hover-color: #ffabab;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:var(--background-color);
  color:var(--font-color);
}

body.dark{
  --font-color: #f2f2f2;
  --background-color: #0e0e0e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}