@font-face {
    font-family: "Inconsolata";
    src:  url('../Inconsolata-VariableFont_wdth\,wght.ttf') format("woff2 supports variations"), url('../Inconsolata-VariableFont_wdth\,wght.ttf') format("woff2-variations");
    font-weight: 100 1000;
}

*{
    color: var(--font-color);
}

a{
    text-decoration: none;
    color:var(--link-color);
    font-weight: 600;
}

a:hover{
    color:var(--link-hover-color);
    text-decoration: underline;
}

h2{
    margin:0;
}

h2, h3{
    margin-bottom:0.5rem;
}

h3{
    margin-top:2rem;
}

p{
    line-height: 1.3rem;
    font-weight:500;    
    margin:0.5rem 0;
}

footer{
    width:40vw;
    margin:auto;
    margin-top:5vh;
    margin-bottom:2vh;
    font-family: "Inconsolata", -apple-system, 'Helvetica Neue';
    font-size:0.85rem;
    font-weight: 600;
}

.main-container{
    width:40vw;
    margin:auto;

    padding-top:2rem;
    font-family: "Inconsolata", -apple-system, 'Helvetica Neue';
    color:#0e0e0e;
}

.main-container.dark{
    --font-color: #f2f2f2;
    --background-color: #0e0e0e;
}

@media only screen and (max-width: 1250px) {
    .main-container, footer{
        width:60vw;
    }
}

@media only screen and (max-width: 850px) {
    .main-container, footer{
        width:90vw;
    }
}