.divider{
    width:60%;
    height:1px;
    border-radius: 10px;
    color:darkgray;
    background-color: darkgray;
}

.divider-ctr{
    display: flex;
    justify-content: center;
}

.about-container, .exp-container{
    margin: 2rem 0;
}

.exp-desc{
    margin-left: 1rem;
}

.exp-desc::before{
    content: "﹥ ";
    margin-left: -1rem;
}

.exp-date{
    color:darkgray;
    font-weight: 500;
    font-style: italic;
}

.resume-btn{
    float:right;
    font-size: 2rem;
}

.exp-role{
    display:flex;
    flex-direction: row;
}

.pup-pic{
    position: absolute;
    margin-top:-200px;
    margin-left: -55px;
    text-align: center;
    width:170px;
    height:170px;
    border-radius: 100px;
    overflow: hidden;
    border:1px solid black;
    box-shadow: 0 26px 58px 0 rgba(0, 0, 0, .22), 0 5px 14px 0 rgba(0, 0, 0, .18);

    z-index: -999999;
    opacity:0;
    transition: all  0.2s ease;
    -webkit-transition: all 0.2s;
}

.pup-pic > img{
    max-width: 100%;
    height:auto;
}

.caption{
    font-size: 1.1rem;
    text-align: center;
    line-height: 1em;
    font-weight: 400;
    /* font-style: normal; */
    color:black;
}

#romeo, #ella{
    font-style: italic;
    font-weight: bold;
    color: var(--link-color);
}

#romeo:hover span.pup-pic, #ella:hover span.pup-pic{
    z-index: 999999;
    opacity:1;
    margin-top:-180px;

    transition: all 0.2s ease;
    -webkit-transition: all 0.2s;
}

@media only screen and (max-width: 850px) {
    .exp-role{
        flex-direction: unset;
        flex-wrap: wrap;
    }

    p{
        margin:0;
    }

    #romeo, #ella{
        color: var(--text-color);
        font-style: normal;
        font-weight: 500;
    }
    #romeo:hover span.pup-pic{
        z-index: -999999;
        opacity:0;
    }
    
    #ella:hover span.pup-pic{
        z-index: -999999;
        opacity:0;
    }
}